exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-economic-js": () => import("./../../../src/pages/economic.js" /* webpackChunkName: "component---src-pages-economic-js" */),
  "component---src-pages-environment-js": () => import("./../../../src/pages/environment.js" /* webpackChunkName: "component---src-pages-environment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investor-relation-annual-report-and-cg-report-js": () => import("./../../../src/pages/investor-relation/annual-report-and-cg-report.js" /* webpackChunkName: "component---src-pages-investor-relation-annual-report-and-cg-report-js" */),
  "component---src-pages-investor-relation-notice-of-agm-js": () => import("./../../../src/pages/investor-relation/notice-of-agm.js" /* webpackChunkName: "component---src-pages-investor-relation-notice-of-agm-js" */),
  "component---src-pages-our-business-js": () => import("./../../../src/pages/our-business.js" /* webpackChunkName: "component---src-pages-our-business-js" */),
  "component---src-pages-our-company-company-profile-js": () => import("./../../../src/pages/our-company/company-profile.js" /* webpackChunkName: "component---src-pages-our-company-company-profile-js" */),
  "component---src-pages-our-company-director-profile-js": () => import("./../../../src/pages/our-company/director-profile.js" /* webpackChunkName: "component---src-pages-our-company-director-profile-js" */),
  "component---src-pages-our-company-senior-management-js": () => import("./../../../src/pages/our-company/senior-management.js" /* webpackChunkName: "component---src-pages-our-company-senior-management-js" */),
  "component---src-pages-our-company-vision-mission-js": () => import("./../../../src/pages/our-company/vision&mission.js" /* webpackChunkName: "component---src-pages-our-company-vision-mission-js" */),
  "component---src-pages-social-js": () => import("./../../../src/pages/social.js" /* webpackChunkName: "component---src-pages-social-js" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */)
}

